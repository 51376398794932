import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className={data.isActive ? "warn" : ""}>
            <td>Do not respond with DECLINES</td>
            <td>
              <input
                type="checkbox"
                checked={data.isActive}
                onChange={(e) =>
                  updateData((d) => {
                    const res = window.confirm(
                      "Redundant Killswitch is for backup-only purpose and should be modified only in case the main Killswitch isn'n working. Do you want to proceed?",
                    );
                    if (!res) {
                      return;
                    }
                    d.isActive = !d.isActive;
                  })
                }
              />
              &nbsp;
              {data.isActive ? (
                <>
                  Redundant Kill switch is active{" "}
                  <i className="fa-solid fa-triangle-exclamation fa-fade"></i>
                </>
              ) : (
                "Inactive"
              )}
            </td>
          </tr>
          <tr>
            <td>Max. declines in last 24 hours</td>
            <td>
              <input
                type="number"
                value={data.maxDeclinedCount24Hours}
                onChange={(e) =>
                  updateData((d) => {
                    let val = e.target.value;
                    val = val >= 200 ? 200 : val;
                    val = val < 1 ? 1 : val;
                    d.maxDeclinedCount24Hours = val;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
