import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import UtilService from "../service/UtilService";
import { useEffect, useState } from "react";
import moment from "moment";

const Component = (props) => {
  const [riskStatistics, updateRiskStatistics] = useImmer(null);
  const [filterConfig, updateFilterConfig] = useImmer({
    trnDateMin: moment().subtract(180, "days").format("YYYY-MM-DDT00:00:00"),
    trnDateMax: moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00"),
  });

  useEffect(async () => {
    const result = await ApiConnector.getFalsePositiveTotalRisks(
      filterConfig.trnDateMin
        ? filterConfig.trnDateMin + "Z"
        : moment().subtract(180, "days").format("YYYY-MM-DDT00:00:00") + "Z",
      filterConfig.trnDateMax
        ? filterConfig.trnDateMax + "Z"
        : moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00") + "Z",
    );
    result.sort((a, b) => b.mean - a.mean);
    updateRiskStatistics(result);
  }, [filterConfig]);

  return (
    <>
      <header className="subheader">
        <h1>Config - Auto allow rule</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Total risk statistics for declined transactions</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right"></h2>
          </div>
        </div>
        {riskStatistics && (
          <>
            <details>
              <summary>Parameters</summary>
              <table>
                <tbody>
                  <tr>
                    <td>Date-time range [min - max UTC]</td>
                    <td>
                      <input
                        type="datetime-local"
                        max={filterConfig.trnDateMax}
                        value={filterConfig.trnDateMin}
                        onChange={(e) =>
                          updateFilterConfig((d) => {
                            d.trnDateMin = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="datetime-local"
                        min={filterConfig.trnDateMin}
                        value={filterConfig.trnDateMax}
                        onChange={(e) =>
                          updateFilterConfig((d) => {
                            d.trnDateMax = e.target.value;
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </details>

            <table className="config-table-double">
              <thead>
                <tr>
                  <th rowSpan={2}>Is fraud</th>
                  <th rowSpan={2}>Declined count</th>
                  <th colSpan={5} style={{ textAlign: "center" }}>
                    Total risk percentiles
                  </th>
                </tr>
                <tr>
                  <th>40%</th>
                  <th>Median</th>
                  <th>60%</th>
                  <th>70%</th>
                  <th>80%</th>
                  <th>90%</th>
                </tr>
              </thead>
              <tbody>
                {riskStatistics.map((statistic, idx) => (
                  <tr key={idx}>
                    <td>{statistic.isFraud}</td>
                    <td>{statistic.totalCount}</td>
                    <td>{Math.round(statistic.percentiles[0] * 10) / 10}</td>
                    <td>{Math.round(statistic.percentiles[1] * 10) / 10}</td>
                    <td>{Math.round(statistic.percentiles[2] * 10) / 10}</td>
                    <td>{Math.round(statistic.percentiles[3] * 10) / 10}</td>
                    <td>{Math.round(statistic.percentiles[4] * 10) / 10}</td>
                    <td>{Math.round(statistic.percentiles[5] * 10) / 10}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </main>
    </>
  );
};

export default Component;
