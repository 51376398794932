import { useImmer } from "use-immer";
import { useEffect } from "react";
import UtilService from "../service/UtilService";
import Note from "./Note";

const Component = ({ config, onChange }) => {
  const panSuppressTemplate = { pan: "", suppressEndTime: "", isNew: true };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addPanSuppress = () => {
    panSuppressTemplate.suppressEndTime =
      UtilService.mapHoursRemainingToIsoOffsetDateTimeString(24);
    updateData((d) => {
      d.generalConfig.panSuppressConfig.panSuppresses.unshift(
        panSuppressTemplate,
      );
    });
  };

  return (
    data && (
      <>
        <h3>
          Kill switch
          <Note
            className="float-right"
            noteTargetPath="generalConfig.killSwitch"
            noteTargetId={data.id}
          />
        </h3>
        <table className="config-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                data.generalConfig.killSwitchConfig.isActive ? "warn" : ""
              }
            >
              <td>Status</td>
              <td>
                <input
                  type="checkbox"
                  checked={data.generalConfig.killSwitchConfig.isActive}
                  onChange={(e) =>
                    updateData((d) => {
                      d.generalConfig.killSwitchConfig.isActive =
                        !d.generalConfig.killSwitchConfig.isActive;
                    })
                  }
                />
                &nbsp;
                {data.generalConfig.killSwitchConfig.isActive ? (
                  <>
                    Kill switch is active{" "}
                    <i className="fa-solid fa-triangle-exclamation fa-fade"></i>
                  </>
                ) : (
                  "Inactive"
                )}
              </td>
            </tr>
            <tr>
              <td>
                Auto-on interval <small>[hours]</small>{" "}
              </td>
              <td>
                <input
                  type="number"
                  value={
                    data.generalConfig.killSwitchConfig
                      .autoTurnOnTimeIntervalHours
                  }
                  onChange={(e) =>
                    updateData((d) => {
                      d.generalConfig.killSwitchConfig.autoTurnOnTimeIntervalHours =
                        e.target.value;
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                Auto-on declined <small>[%]</small>
              </td>
              <td>
                <input
                  type="number"
                  step={0.01}
                  value={
                    data.generalConfig.killSwitchConfig
                      .autoTurnOnDeclinedTransactionThresholdRatio * 100
                  }
                  onChange={(e) =>
                    updateData((d) => {
                      let val = e.target.value / 100;
                      val = val > 0.002 ? 0.002 : val;
                      val = val < 0 ? 0 : val;
                      d.generalConfig.killSwitchConfig.autoTurnOnDeclinedTransactionThresholdRatio =
                        val;
                    })
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>

        <h3>
          Pan suppress
          <Note
            className="float-right"
            noteTargetPath="generalConfig.panSuppress"
            noteTargetId={data.id}
          />
        </h3>
        <table className="config-table table-with-button">
          <thead>
            <tr>
              <th>Pan</th>
              <th>
                Remaining <small>[hours]</small>
              </th>
              <th>
                <a
                  className="fa-solid fa-plus"
                  onClick={() => addPanSuppress()}
                ></a>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.generalConfig.panSuppressConfig.panSuppresses.map(
                (ps, index) => (
                  <tr className="" key={index}>
                    <td>
                      <input
                        type="text"
                        value={ps.pan}
                        disabled={!ps.isNew}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.panSuppressConfig.panSuppresses[
                              index
                            ].pan = e.target.value;
                          })
                        }
                      />
                    </td>

                    <td>
                      <input
                        type="number"
                        value={UtilService.mapIsoOffsetDateTimeStringToHoursRemaining(
                          ps.suppressEndTime,
                        )}
                        onChange={(e) =>
                          updateData((d) => {
                            d.generalConfig.panSuppressConfig.panSuppresses[
                              index
                            ].suppressEndTime =
                              UtilService.mapHoursRemainingToIsoOffsetDateTimeString(
                                e.target.value,
                              );
                          })
                        }
                      />
                    </td>
                    <td>
                      <a
                        className="fa-solid fa-trash-can"
                        onClick={(e) =>
                          updateData((d) => {
                            d.generalConfig.panSuppressConfig.panSuppresses.splice(
                              index,
                              1,
                            );
                          })
                        }
                      ></a>
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </table>
      </>
    )
  );
};

export default Component;
