import UtilService from "../service/UtilService";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, customerHistoryConfig, onChange }) => {
  // const [data, updateData] = useImmer(config);
  //
  // useEffect(() => {
  //   onChange(data);
  // }, [data]);
  //
  // useEffect(() => {
  //   updateData(config);
  // }, [config]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>{customerHistoryConfig.daysMin}</td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>{customerHistoryConfig.daysMax}</td>
          </tr>
          <tr>
            <td>Score on new monitored Facilities visit</td>
            <td>Driven by the Facility rule score</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
