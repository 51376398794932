import { useImmer } from "use-immer";
import ApiConnector from "../connector/ApiConnector";
import { useEffect } from "react";
import moment from "moment/moment";
import UtilService from "../service/UtilService";

const Component = () => {
  const [auditlogs, updateAuditlogs] = useImmer(null);
  const [filterConfig, updateFilterConfig] = useImmer({
    createdMin: moment().subtract(7, "days").format("YYYY-MM-DDTHH:mm:ss"),
    createdMax: moment().subtract(0, "days").format("YYYY-MM-DDTHH:mm:ss"),
    pageSize: 100,
  });

  useEffect(async () => {
    const result = await ApiConnector.getAuditlogs(
      filterConfig.createdMin
        ? filterConfig.createdMin + "Z"
        : moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00") + "Z",
      filterConfig.createdMax
        ? filterConfig.createdMax + "Z"
        : moment().subtract(0, "days").format("YYYY-MM-DDT00:00:00") + "Z",
      filterConfig.pageSize,
    );
    result.sort((a, b) => b.mean - a.mean);
    updateAuditlogs(result);
  }, [filterConfig]);

  return (
    <>
      <header className="subheader">
        <h1>Auditlog</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>User activity</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right"></h2>
          </div>
        </div>

        {auditlogs && (
          <>
            <details>
              <summary>Filtering parameters</summary>
              <table>
                <thead>
                  <tr>
                    <th>Filter</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Date-time range [min - max UTC]</td>
                    <td>
                      <input
                        type="datetime-local"
                        max={filterConfig.createdMax}
                        value={filterConfig.createdMin}
                        onChange={(e) =>
                          updateFilterConfig((d) => {
                            d.createdMin = e.target.value;
                          })
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="datetime-local"
                        min={filterConfig.createdMin}
                        value={filterConfig.createdMax}
                        onChange={(e) =>
                          updateFilterConfig((d) => {
                            d.createdMax = e.target.value;
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Page size</td>
                    <td colSpan={2}>
                      <input
                        type="number"
                        max={500}
                        value={filterConfig.pageSize}
                        onChange={(e) =>
                          updateFilterConfig((d) => {
                            d.pageSize = e.target.value;
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </details>

            {auditlogs.map((auditlog, index) => (
              <div key={index}>
                <div className="row">
                  <div className="col">
                    <b>{auditlog.eventType}</b>
                    <br />
                    <small>
                      {UtilService.isoDateToReadable(auditlog.created)},{" "}
                      {auditlog.userId} <br />
                    </small>
                  </div>
                  <div className="col-55">
                    <textarea
                      className="textarea-nowrap"
                      rows="2"
                      value={auditlog.description}
                      readOnly={true}
                    ></textarea>
                  </div>
                </div>
                <br />
                <br />
              </div>
            ))}
          </>
        )}
      </main>
    </>
  );
};

export default Component;
