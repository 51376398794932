import { useImmer } from "use-immer";
import { useEffect } from "react";
import UtilService from "../../service/UtilService";
import ObjectUtilService from "../../service/ReportPageService";
import _ from "lodash-contrib";

const Component = ({ sampleTransactions, onChange }) => {
  const [
    displayableTransactionAttributes,
    updateDisplayableTransactionAttributes,
  ] = useImmer([]);
  const [displayedTransactionAttributes, updateDisplayedTransactionAttributes] =
    useImmer([]);
  const LOCAL_STORAGE_NAME = "displayedTransactionAttributes";
  const DEFAULT_DISPLAYED_ATTRIBUTES = [
    ["trnDate"],
    ["transactionType"],
    ["riskEvaluation", "totalRisk"],
    ["pan"],
    ["companyId"],
    ["merchantCode"],
    ["products", 0, "quantity"],
    ["products", 0, "measure"],
    ["odometer"],
    ["rejectReasonCode"],
    ["isFraud"],
    ["riskEvaluation", "velocityToRecentMerchantKmh"],
    ["riskEvaluation", "configId"],
    ["id"],
    ["languageCode"],
  ];

  useEffect(() => {
    // Load configuration from local storage or create new one

    const isStorageInited = getConfig() !== null;
    if (!isStorageInited) {
      setConfig(DEFAULT_DISPLAYED_ATTRIBUTES);
    }
    const config = getConfig();

    // Collect all attributes present in transaction samples

    let allTransactionAttributes = [];
    let i = 0;
    for (let transaction of sampleTransactions) {
      // Collect transaction attributes

      for (let attribute of Object.keys(transaction)) {
        allTransactionAttributes.push([attribute]);
      }

      // Collect nested risk evaluation attributes

      if (transaction.riskEvaluation) {
        for (let attribute of Object.keys(transaction.riskEvaluation)) {
          allTransactionAttributes.push(["riskEvaluation", attribute]);
        }
      }

      // Collect nested products attributes

      if (transaction.products && transaction.products.length >= 1) {
        for (let attribute of Object.keys(transaction.products[0])) {
          allTransactionAttributes.push(["products", 0, attribute]);
        }
      }

      if (++i === 10) {
        break; // We don't need more than 5 samples
      }
    }

    allTransactionAttributes = _.uniqBy(allTransactionAttributes, (e) =>
      JSON.stringify(e),
    );

    // Collect displayable attributes

    const tmpDisplayableAttributes = allTransactionAttributes.filter(
      (n) => !config.some((c) => JSON.stringify(c) === JSON.stringify(n)),
    );
    tmpDisplayableAttributes.sort((a, b) =>
      a.toString().localeCompare(b.toString()),
    );

    // Update

    updateDisplayableTransactionAttributes(tmpDisplayableAttributes);
    updateDisplayedTransactionAttributes(config);
  }, [sampleTransactions]);

  useEffect(() => {
    onChange(displayedTransactionAttributes);
  }, [displayedTransactionAttributes]);

  const getConfig = () => {
    return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_NAME));
  };

  const setConfig = (selectedColumnNames) => {
    window.localStorage.setItem(
      LOCAL_STORAGE_NAME,
      JSON.stringify(selectedColumnNames),
    );
  };

  const moveRight = (idx) => {
    const newSelected = [
      ...displayedTransactionAttributes,
      displayableTransactionAttributes[idx],
    ];
    updateDisplayedTransactionAttributes(newSelected);
    setConfig(newSelected);
    updateDisplayableTransactionAttributes([
      ...displayableTransactionAttributes.filter((s, i) => idx !== i),
    ]);
  };

  const moveLeft = (idx) => {
    updateDisplayableTransactionAttributes(
      [
        ...displayableTransactionAttributes,
        displayedTransactionAttributes[idx],
      ].sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b))),
    );
    const newSelected = [
      ...displayedTransactionAttributes.filter((s, i) => idx !== i),
    ];
    updateDisplayedTransactionAttributes(newSelected);
    setConfig(newSelected);
  };

  const moveUp = (idx) => {
    updateDisplayedTransactionAttributes((d) => {
      if (idx === 0) {
        return;
      }

      const upperItem = d[idx - 1];
      const currentItem = d[idx];

      d[idx - 1] = currentItem;
      d[idx] = upperItem;

      setConfig(d);
    });
  };

  const moveDown = (idx) => {
    updateDisplayedTransactionAttributes((d) => {
      if (idx === displayableTransactionAttributes.length - 1) {
        return;
      }

      const downItem = d[idx + 1];
      const currentItem = d[idx];

      d[idx + 1] = currentItem;
      d[idx] = downItem;

      setConfig(d);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Displayable attributes</th>
            <th>Displayed attributes</th>
          </tr>
        </thead>
        <tbody>
          {[
            ...Array(
              Math.max(
                displayableTransactionAttributes.length,
                displayedTransactionAttributes.length,
              ),
            ),
          ].map((_, idx) => (
            <tr className="trnohover" key={idx}>
              <td>
                {idx < displayableTransactionAttributes.length && (
                  <>
                    <span>
                      {UtilService.camelCaseToReadable(
                        displayableTransactionAttributes[idx],
                      )}
                    </span>
                    <span className="float-right">
                      <a
                        className="fa-solid fa-angles-right"
                        onClick={() => moveRight(idx)}
                      ></a>
                    </span>
                  </>
                )}
              </td>
              <td>
                {idx < displayedTransactionAttributes.length && (
                  <>
                    <span>
                      {UtilService.camelCaseToReadable(
                        displayedTransactionAttributes[idx],
                      )}
                    </span>
                    <span className="float-right">
                      <a
                        className="fa-solid fa-angles-left"
                        onClick={() => moveLeft(idx)}
                      ></a>
                      &nbsp;&nbsp;
                      <a
                        className="fa-solid fa-angle-up"
                        onClick={() => moveUp(idx)}
                      ></a>
                      &nbsp;&nbsp;
                      <a
                        className="fa-solid fa-angle-down"
                        onClick={() => moveDown(idx)}
                      ></a>
                    </span>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
